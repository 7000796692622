import React from 'react';
import { List, Datagrid, TextField, ReferenceField, Filter, TextInput, SelectInput, DateField } from 'react-admin';
import { UNITEL_CARRIER_BILLING_SUBSCRIPTION_STATUS } from '../../constants';

const SubscriptionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Утасны дугаар" source="phoneNumber" alwaysOn />
    <TextInput label="User Id" source="userId" alwaysOn />
    <TextInput label="Reference Id" source="referenceId" alwaysOn />
    <SelectInput source="status" choices={UNITEL_CARRIER_BILLING_SUBSCRIPTION_STATUS} />
  </Filter>
);


export const CarrierBillingSubscriptionList = props => (
  <List {...props} filters={<SubscriptionFilter />}>
    <Datagrid optimized>
      <TextField source="id" />
      <TextField label="Reference Id" source="referenceId" />
      <TextField label="Утасны дугаар" source="phoneNumber" />
      <TextField label="Package Id" source="packageId" />
      <ReferenceField source="userId" label="Хэрэглэгч" reference="users">
        <TextField label="Хэрэглэгч" source="firstName" />
      </ReferenceField>
      <TextField label="Төрөл" source="recurringPeriod" />
      <TextField label="Төлөв" source="status" />
      <DateField label="Дуусах өдөр" source="expiredAt" showTime />
    </Datagrid>
  </List>
);
