import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Create,
  CreateButton,
  Edit,
  Filter,
  ImageField,
  FileField,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  SelectInput,
  email,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Нэр" source="firstName" alwaysOn />
  </Filter>
);


const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const CreatorList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Овог" source="lastName" />
      <TextField label="Нэр" source="firstName" />
      <TextField label="И-майл" source="email" />
      <TextField label="Мэргэжил" source="profession" />
      <TextField label="Төрөл" source="type" />
      <ImageField label="Зураг" source="avatar" />
      <EditButton />
    </Datagrid>
  </List>
);

const CreatorTitle = ({ record }) => {
  const { firstName } = record;
  return <span>Creator {record ? `"${firstName}"` : ''}</span>;
};

export const CreatorEdit = props => (
  <Edit title={<CreatorTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Овог" source="lastName" />
      <TextInput label="Нэр" source="firstName" />
      <TextInput label="И-майл" source="email" validate={email()} />
      <TextInput label="Мэргэжил" source="profession" />
      <TextInput multiline fullWidth rows={5} label="Намтар" source="bio" />
      <ImageField source="avatar" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput
        label="төрөл"
        source="type"
        defaultValue="expert"
        choices={[
          { id: 'master', name: 'master' },
          { id: 'expert', name: 'expert' },
          { id: 'foreign', name: 'foreign' },
        ]}
      />
    </SimpleForm>
  </Edit>
);
const validateCreator = (values) => {
  const errors = {};
  if (!values.lastName) {
    errors.lastName = 'Овог оруулна уу';
  }
  if (!values.firstName) {
    errors.firstName = 'Нэр оруулна уу';
  }
  if (!values.email) {
    errors.email = 'И-майл оруулна уу';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'И-майл хаяг буруу байна';
  }
  if (!values.profession) {
    errors.profession = 'Мэргэжил оруулна уу';
  }
  if (!values.bio) {
    errors.bio = 'Намтар оруулна уу';
  }
  if (!values.file) {
    errors.file = 'Зураг оруулна уу';
  }
  if (!values.type) {
    errors.type = 'Төрөл сонгоно уу';
  }
  return errors;
};


export const CreatorCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateCreator}>
      <TextInput label="Овог" source="lastName" required/>
      <TextInput label="Нэр" source="firstName" required/>
      <TextInput label="И-майл" source="email" validate={email()} required/>
      <TextInput label="Мэргэжил" source="profession" required/>
      <TextInput multiline fullWidth rows={5} label="Намтар" source="bio" required/>
      <FileInput source="file" label="Зураг" accept="image/*" required>
        <FileField source="image" title="image"/>
      </FileInput>
      <SelectInput
        label="төрөл"
        source="type"
        defaultValue="expert"
        choices={[
          { id: 'master', name: 'master' },
          { id: 'expert', name: 'expert' },
          { id: 'foreign', name: 'foreign' },
        ]}
        required
      />
    </SimpleForm>
  </Create>
);

